import { ErrorBoundary, Provider } from '@rollbar/react'; // <-- Provider imports 'rollbar' for us
import React, { ReactNode } from 'react';
import { Configuration } from 'rollbar';

// same configuration you would create for the Rollbar.js SDK
const rollbarConfig: Configuration = {
  environment: window.location.host === 'supporta.com' ? 'production' : 'local',
  enabled: window.location.host === 'supporta.com',
  accessToken: '5ac3b9556b934f729434328f4badd3b7', // post_client_item
  captureUncaught: true,
  itemsPerMinute: 1,
  captureIp: 'anonymize',
  // The host that must be present in one of the stack frames.
  // This is used to ignore errors in browser extensions and third-party scripts.
  hostSafeList: ['supporta.com'],
  ignoredMessages: [
    'ResizeObserver loop completed with undelivered notifications.',
  ],
  verbose: true,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
      },
    },
  },
};

export default function RollBarProvider({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </Provider>
  );
}
